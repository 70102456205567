import { User } from '../types/user';
import { supabase } from '../lib/supabase';

export class UserService {
  private static instance: UserService;

  private constructor() {}

  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  private async checkAdminAccess(): Promise<void> {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user?.id) {
      throw new Error('Not authenticated');
    }

    const { data: currentUser } = await supabase
      .from('profiles')
      .select('is_admin')
      .eq('id', session.user.id)
      .single();

    if (!currentUser?.is_admin) {
      throw new Error('Unauthorized: Admin access required');
    }
  }

  async getUsers(): Promise<User[]> {
    await this.checkAdminAccess();

    try {
      // Use the function to get both profile and auth data
      const { data, error } = await supabase.rpc('get_users_with_login_info');

      if (error) {
        console.error('Error fetching users:', error);
        throw new Error('Failed to fetch users');
      }

      return data || [];
    } catch (error: any) {
      console.error('Error in getUsers:', error);
      throw new Error('Failed to fetch users');
    }
  }

  async updatePassword(userId: string, newPassword: string): Promise<void> {
    await this.checkAdminAccess();

    try {
      // First verify the password meets requirements
      if (!newPassword || newPassword.length < 6) {
        throw new Error('Password must be at least 6 characters long');
      }

      // Update the user's password using the built-in Supabase function
      const { error } = await supabase.rpc('admin_update_user_password', {
        user_id: userId,
        new_password: newPassword
      });

      if (error) {
        throw error;
      }
    } catch (error: any) {
      console.error('Error updating password:', error);
      throw new Error(error.message || 'Failed to update password');
    }
  }

  async createUser(email: string, password: string, fullName: string, isAdmin: boolean): Promise<void> {
    await this.checkAdminAccess();

    try {
      // Create the auth user
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
          data: {
            full_name: fullName,
            is_admin: isAdmin
          }
        }
      });

      if (signUpError) {
        if (signUpError.message === 'User already registered') {
          throw new Error('A user with this email already exists');
        }
        throw signUpError;
      }
      if (!authData?.user) throw new Error('Failed to create user account');

      // Wait for the trigger to create the profile
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Verify the profile was created
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', authData.user.id)
        .single();

      if (profileError || !profile) {
        // Clean up if profile creation failed
        await supabase.auth.admin.deleteUser(authData.user.id);
        throw new Error('Failed to create user profile');
      }
    } catch (error: any) {
      console.error('Error creating user:', error);
      throw new Error(error.message || 'Failed to create user');
    }
  }

  async updateUser(userId: string, updates: Partial<User>): Promise<void> {
    await this.checkAdminAccess();

    try {
      if (updates.is_admin === false) {
        const { data: userToUpdate } = await supabase
          .from('profiles')
          .select('is_admin')
          .eq('id', userId)
          .single();

        if (userToUpdate?.is_admin) {
          const { count } = await supabase
            .from('profiles')
            .select('id', { count: 'exact', head: true })
            .eq('is_admin', true);

          if (count === 1) {
            throw new Error('Cannot remove admin status from the last admin user');
          }
        }
      }

      const { error } = await supabase
        .from('profiles')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (error) throw error;
    } catch (error: any) {
      throw new Error(error.message || 'Failed to update user');
    }
  }

  async deleteUser(userId: string): Promise<void> {
    await this.checkAdminAccess();

    try {
      const { error: deleteError } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);

      if (deleteError) {
        if (deleteError.message.includes('last admin')) {
          throw new Error('Cannot delete the last admin user');
        }
        throw deleteError;
      }
    } catch (error: any) {
      throw new Error(error.message || 'Failed to delete user');
    }
  }
}